import { Fragment } from "react";
import { useMutation } from "react-query";
import { Divider } from "semantic-ui-react";
import { API } from "../../config/api";
import { ConfirmButton } from "../../components/confirmButton";
import { notification } from "antd";

export const SyncView = () => {
  const { mutateAsync: syncAllPermissions } = useMutation(
    () => API.asset.syncAllPermissions(),
    {
      onSuccess: () => {
        notification.success({ message: "Sync all permissions job sent" });
      },
      onError: (e: Error) => {
        notification.error({ message: `Error: ${e?.message || e}` });
      },
    }
  );

  const { mutateAsync: syncIlevel } = useMutation(
    () => API.asset.syncIlevel(),
    {
      onSuccess: () => {
        notification.success({ message: "Synced all ilevel companies" });
      },
      onError: (e: Error) => {
        notification.error({ message: `Error: ${e?.message || e}` });
      },
    }
  );

  const { mutateAsync: syncStrategies } = useMutation(
    () => API.strategy.syncAllPermissions(),
    {
      onSuccess: () => {
        notification.success({
          message: "Sync all strategies folders permissions job sent",
        });
      },
      onError: (e: Error) => {
        notification.error({ message: `Error: ${e?.message || e}` });
      },
    }
  );

  return (
    <Fragment>
      <ConfirmButton
        confirmText="Confirm!"
        buttonText="Sync ilevel companies"
        onConfirm={syncIlevel}
      />
      <Divider hidden={true} />
      <ConfirmButton
        confirmText="Confirm!"
        buttonText="Sync all company folder permissions"
        onConfirm={syncAllPermissions}
      />
      <Divider hidden={true} />
      <ConfirmButton
        confirmText="Confirm!"
        buttonText="Sync strategy folders permissions"
        onConfirm={syncStrategies}
      />
    </Fragment>
  );
};
