import { Box } from "@partech/react-ui";
import { findIndex, first } from "lodash";
import { useMemo } from "react";
import { Outlet, useParams, useLocation, useNavigate } from "react-router-dom";
import { Tab } from "semantic-ui-react";
import { ROUTES } from "../../config/routes";

const getPanes = (outlet: any) => [
  { menuItem: "Assets", render: () => outlet, name: "assets" },
  { menuItem: "Users", render: () => outlet, name: "users" },
  { menuItem: "Strategies", render: () => outlet, name: "strategies" },
  { menuItem: "Sync", render: () => outlet, name: "sync" },
];

const outlet = (
  <Box style={{ padding: 30 }}>
    <Outlet />
  </Box>
);

export const MenuWrapper = (props: any) => {
  const panes = useMemo(() => getPanes(outlet), []);
  const v = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  let realTabIndex = useMemo(() => {
    const firstPath = first(
      pathname
        .replace(ROUTES.root, "")
        .split("/")
        .filter((v) => v)
    );
    return findIndex(panes, (pane) => pane.name === firstPath);
  }, [pathname, panes]);

  if (realTabIndex === -1) {
    realTabIndex = 0;
  }

  return (
    <Tab
      menu={{ secondary: true, pointing: true }}
      activeIndex={realTabIndex}
      onTabChange={(event, { activeIndex }) => {
        navigate(panes[activeIndex as number].name);
      }}
      panes={panes}
    />
  );
};
