import { Box } from "@partech/react-ui";
import produce from "immer";
import { filter, find, findIndex, map, size, some } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Divider,
  Grid,
  Icon,
  Label,
  List,
  Modal,
  Header,
  Dropdown,
  Button,
  Image,
  Segment,
  Popup,
} from "semantic-ui-react";
import { Input, notification, Popover, Select } from "antd";
import { Asset, Fund, Group } from "../../types";
import { useMutation, useQueryClient } from "react-query";
import { API } from "../../config/api";

type ViewableGroups = { name: string; removed: boolean; added: boolean }[];
const stringToOption = (str: string) => ({ key: str, value: str, text: str });
const stringToViewableGroup = (
  str: string,
  removed = false,
  added = false
) => ({ name: str, removed, added });
const groupsToOptions = (groups: ViewableGroups) =>
  map(groups, (f) => stringToOption(f.name));

export const useModal = (initState = false) => {
  const [showModal, setShowModal] = useState(initState);

  const onOpen = useCallback(() => {
    setShowModal(true);
  }, []);
  const onClose = useCallback(() => {
    setShowModal(false);
  }, []);

  return {
    showModal,
    openModal: onOpen,
    closeModal: onClose,
  };
};

const useAliases = (_aliases: string[], dependencies?: any[]) => {
  const [alias, setAlias] = useState("");
  const [aliases, setAliases] = useState<any[]>();

  useEffect(() => {
    setAlias("");
    setAliases([]);
  }, [...(dependencies || [])]);

  useEffect(() => {
    const mapped = map(_aliases, (alias) => ({
      added: false,
      removed: false,
      alias,
    }));

    if (_aliases && mapped) {
      setAliases(mapped);
    }
  }, [_aliases]);

  const onNewAlias = useCallback(() => {
    const newAliases = produce(aliases || [], (prevAliases: any[]) => {
      let existingAlias = find(
        prevAliases,
        (f) =>
          f.alias.toLowerCase().trim().replace(/\s+/g, " ") ===
          alias.toLowerCase().trim().replace(/\s+/g, " ")
      );
      if (existingAlias && existingAlias.removed) {
        existingAlias.removed = false;
        return prevAliases;
      } else if (!existingAlias) {
        return [...prevAliases, { alias, added: true }];
      }
    });

    setAliases(newAliases);
    setAlias("");
  }, [aliases, alias, setAlias]);

  const onDeleteAlias = useCallback(
    (alias: string) => {
      const newAliases = produce(aliases || [], (prevAliases: any[]) => {
        const existingAliasIndex = findIndex(
          prevAliases,
          (f) => f.alias === alias
        );
        let existingAlias;

        if (existingAliasIndex >= 0) {
          existingAlias = prevAliases[existingAliasIndex];
        }

        if (existingAlias && existingAlias.added) {
          return [
            ...prevAliases.slice(0, existingAliasIndex),
            ...prevAliases.slice(existingAliasIndex + 1),
          ];
        } else if (existingAlias) {
          existingAlias.removed = !existingAlias.removed;
        }
      });
      setAliases(newAliases);
    },
    [aliases]
  );

  const anyUpdate = useMemo(() => {
    return some(aliases, (f) => f.removed || f.added);
  }, [aliases]);

  return { onNewAlias, onDeleteAlias, aliases, anyUpdate, alias, setAlias };
};

const useGroups = (
  usedGroups: Group[],
  allGroups: Group[],
  dependencies: any[]
) => {
  const [groups, setGroups] = useState<any[]>([]);

  useEffect(() => {
    setGroups(map(usedGroups, (g) => stringToViewableGroup(g)));
  }, [usedGroups, ...dependencies]);

  const showedOptions = useMemo(() => {
    return map(
      filter(
        allGroups,
        (group) => !Boolean(groups.find((g) => g.name === group && !g.removed))
      ),
      (g) => stringToOption(g)
    );
  }, [groups, allGroups]);

  const onSelectOption = useCallback(
    (group: string) => {
      const newGroups = produce(groups || [], (prevGroups: any[]) => {
        let existingGroup = find(prevGroups, (f) => f.name === group);
        if (existingGroup && existingGroup.removed) {
          existingGroup.removed = false;
          return prevGroups;
        }
        return [...prevGroups, { name: group, added: true }];
      });

      setGroups(newGroups);
    },
    [groups]
  );

  const onDeleteOption = useCallback(
    (group: string) => {
      const newGroups = produce(groups || [], (prevGroups: any[]) => {
        const existingGroupIndex = findIndex(
          prevGroups,
          (f) => f.name === group
        );
        let existingGroup;

        if (existingGroupIndex >= 0) {
          existingGroup = prevGroups[existingGroupIndex];
        }

        if (existingGroup && existingGroup.added) {
          return [
            ...prevGroups.slice(0, existingGroupIndex),
            ...prevGroups.slice(existingGroupIndex + 1),
          ];
        } else if (existingGroup) {
          existingGroup.removed = !existingGroup.removed;
        }
      });
      setGroups(newGroups);
    },
    [groups]
  );

  const anyUpdate = useMemo(() => {
    return some(groups, (g) => g.removed || g.added);
  }, [groups]);

  return { groups, onDeleteOption, onSelectOption, showedOptions, anyUpdate };
};

export const EditAsset = (props: {
  asset: Asset | undefined | null;
  onEditionDone?: undefined | (() => void | Promise<void>);
  funds: Fund[];
  groups: Group[];
}) => {
  const queryClient = useQueryClient();

  const { mutateAsync: syncPermissions, isLoading: isPermissionsSyncLoading } =
    useMutation(
      () => API.asset.syncPermissions({ assetId: asset?.id as string }),
      {
        onSuccess: () => {
          notification.success({ message: "Sync permissions job sent" });
        },
      }
    );

  const { mutateAsync: updateAsset, isLoading: updateLoading } = useMutation(
    ({
      aliases,
      allowedGroups,
      assetId,
      fund,
    }: {
      assetId: string;
      aliases: string[];
      fund: string;
      allowedGroups: string[];
    }) => API.asset.update({ aliases, allowedGroups, assetId, fund }),
    {
      onSuccess: (obj) => {
        const { aliases, allowed_groups, fund, id } = obj;

        queryClient.setQueryData(["assets"], (prevAssets: any) => {
          const index = findIndex(
            prevAssets,
            (oldAsset: any) => oldAsset.id === id
          );
          if (index >= 0) {
            prevAssets[index] = {
              ...prevAssets[index],
              fund,
              allowed_groups,
              aliases,
            };
          }
          return [...(prevAssets || [])];
        });
      },
    }
  );

  const { funds, groups: _groups } = props;
  const [fund, setFund] = useState(props?.asset?.fund as string);
  const { asset, onEditionDone } = props || {};
  const { closeModal, openModal, showModal } = useModal(false);
  const {
    groups,
    onDeleteOption: onDeleteGroup,
    onSelectOption: onAddGroup,
    showedOptions: showedGroupOptions,
    anyUpdate: groupsUpdated,
  } = useGroups(asset?.allowed_groups as Group[], _groups, [asset?.id]);

  useEffect(() => {
    setFund(props?.asset?.fund || "");
  }, [props?.asset?.fund, props?.asset?.id]);

  const fundOptions = useMemo(() => {
    return map(funds, ({ name }) => ({ value: name, text: name, key: name }));
  }, [funds]);

  const { ilevel_name, aliases: _aliases, id: assetId } = asset || {};

  useEffect(() => {
    if (asset) {
      openModal();
    }
  }, [asset, openModal]);

  const onCloseModal = useCallback(() => {
    if (updateLoading) {
      return;
    }
    if (onEditionDone) {
      onEditionDone();
    }
    closeModal();
  }, [closeModal, onEditionDone, updateLoading]);

  const {
    aliases,
    onDeleteAlias,
    onNewAlias,
    alias,
    setAlias,
    anyUpdate: aliasesUpdated,
  } = useAliases(_aliases as string[], [asset?.id]);

  const onSubmit = useCallback(async () => {
    const newAliases = [];
    const newGroups = [];
    for (const { removed, alias } of aliases || []) {
      if (!removed) {
        newAliases.push(alias);
      }
    }

    for (const { removed, name } of groups || []) {
      if (!removed) {
        newGroups.push(name);
      }
    }
    await updateAsset({
      aliases: newAliases,
      allowedGroups: newGroups,
      fund,
      assetId: assetId as string,
    })
      .then(onCloseModal)
      .catch((e) => {
        notification.open({
          message: "Error while updating asset",
          description: e.message,
        });
      });
  }, [aliases, fund, assetId, groups, onCloseModal, updateAsset]);

  return (
    <Modal
      open={showModal}
      onClose={onCloseModal}
      header={ilevel_name || "Loading..."}
      actions={["Snooze", { key: "done", content: "Done", positive: true }]}
    >
      <Modal.Content>
        <Grid.Row>
          <Grid.Column>
            <Button
              floated="right"
              animated="vertical"
              onClick={() => syncPermissions()}
            >
              <Button.Content hidden>Sync</Button.Content>
              <Button.Content visible>
                <Icon name="sync" />
              </Button.Content>
            </Button>
            <Header floated="left" size="large">
              {props.asset?.ilevel_name}
            </Header>
            <Segment basic clearing></Segment>
          </Grid.Column>
          <Grid.Column>
            <Divider hidden={true} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Header size="medium">Aliases:</Header>
            <Input
              width={2}
              placeholder="Add alias and press enter"
              value={alias}
              onChange={(v) => {
                console.log(v.target.value, "bla");
                setAlias(v.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onNewAlias();
                }
              }}
              // fluid
              // onChange={(input, { value }) => {
              //   console.log(input, value, "vvv");
              // }}
            />
          </Grid.Column>
          <Grid.Column>
            <Divider hidden={true} />
          </Grid.Column>
          <List.Item>
            <List.Content>
              {map(aliases, ({ alias, removed, added }) => (
                <Label
                  color={added ? "green" : removed ? "red" : undefined}
                  image
                >
                  {removed && (
                    <Icon
                      onClick={() => onDeleteAlias(alias)}
                      name="add"
                      style={{ cursor: "pointer" }}
                    />
                  )}
                  {alias}
                  {!removed && (
                    <Icon onClick={() => onDeleteAlias(alias)} name="delete" />
                  )}
                </Label>
              ))}
            </List.Content>
          </List.Item>
        </Grid.Row>
        <Grid.Row>
          <Divider hidden={true} />
          <Grid.Column>
            <Header size="medium">Fund:</Header>
            <Select
              placeholder="Fund"
              value={fund}
              onChange={(value) => {
                setFund(value);
              }}
              style={{ width: "100%" }}
              options={fundOptions}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Divider hidden={true} />
          <Header size="medium">Chinese Wall:</Header>
          {Boolean(size(showedGroupOptions)) && (
            <>
              <Grid.Column>
                <Dropdown
                  fluid
                  search
                  selection
                  minCharacters={1}
                  onChange={(e, { value }) => {
                    onAddGroup(value as string);
                  }}
                  options={showedGroupOptions}
                />
              </Grid.Column>
              <Grid.Column>
                <Divider hidden={true} />
              </Grid.Column>
            </>
          )}

          <Grid.Column>
            <List horizontal size={"small"}>
              {map(groups, ({ name, added, removed }) => {
                return (
                  <List.Item key={name}>
                    <List.Content>
                      <Label
                        color={added ? "green" : removed ? "red" : undefined}
                        image
                      >
                        {removed && (
                          <Icon
                            onClick={() => onDeleteGroup(name)}
                            name="add"
                            style={{ cursor: "pointer" }}
                          />
                        )}
                        {name}
                        {!removed && (
                          <Icon
                            onClick={() => onDeleteGroup(name)}
                            name="delete"
                          />
                        )}
                      </Label>
                    </List.Content>
                  </List.Item>
                );
              })}
            </List>
          </Grid.Column>
          {/* <Grid.Column>
            <Button
              color="green"
              disabled={!anyUpdate}
              loading={isLoadingStrategies}
              onClick={() => setShowConfirm(true)}
            >
              Submit
            </Button>
          </Grid.Column> */}
        </Grid.Row>
      </Modal.Content>
      <Modal.Actions>
        <Button
          disabled={updateLoading}
          color="black"
          onClick={() => onCloseModal()}
        >
          Cancel
        </Button>
        <Button
          loading={updateLoading}
          content="Update"
          labelPosition="right"
          icon="checkmark"
          onClick={onSubmit}
          disabled={
            !(
              aliasesUpdated ||
              groupsUpdated ||
              (fund && props?.asset?.fund !== fund)
            )
          }
          positive
        />
      </Modal.Actions>
    </Modal>
  );
};
