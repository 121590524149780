import produce from "immer";
import { find, map, reverse, sortBy } from "lodash";
import { useCallback, useEffect, useReducer } from "react";
import { useMutation, useQuery } from "react-query";
import { Button, Form, Grid, Input, Table } from "semantic-ui-react";
import { API } from "../../config/api";
import { User } from "../../types";

interface UserData extends User {
  newAlias: string;
}

const reducer: (
  prevState: { users: UserData[]; column: string; direction: string },
  action: {
    type?: "CHANGE_SORT" | "INIT" | "UPDATE_ALIAS" | "UPDATE_INITIAL_ALIAS";
    column?: "email" | "alias";
    newUsers?: UserData[];
    alias?: string;
    userId?: string;
  }
) => { users: UserData[]; column: string; direction: string } = produce(
  (prevState, action) => {
    switch (action.type) {
      case "CHANGE_SORT":
        if (prevState.column === action.column) {
          prevState.users = reverse(prevState.users);
          prevState.direction =
            prevState.direction === "ascending" ? "descending" : "ascending";
        } else {
          console.log(
            "else case",
            prevState.direction,
            prevState.column,
            action.column
          );
          prevState.users = sortBy(prevState.users, [action.column]) as any;
          prevState.direction = "ascending";
          prevState.column = action.column as any;
        }
        break;
      case "INIT":
        console.log("INITING");
        return {
          column: "",
          direction: "",
          users: map(action.newUsers, (u) => ({ ...u, newAlias: u.alias })),
        };
      case "UPDATE_ALIAS":
        return {
          users: map(prevState.users, (u) => ({
            ...u,
            newAlias: u.id === action.userId ? action.alias : u.newAlias,
          })),
        };
      case "UPDATE_INITIAL_ALIAS":
        const user = find(prevState.users, (u) => u.id === action.userId);
        if (user) {
          user.alias = action.alias as any;
        }
        return;
      default:
        throw new Error();
    }
  }
);

export const UserList = () => {
  const [state, dispatch] = useReducer(reducer, {
    users: [],
    column: "",
    direction: "",
  });

  const { data: _users } = useQuery("users", () => API.user.list());

  const { mutateAsync: updateUserAlias } = useMutation(
    ({ id, alias }: { id: string; alias: string }) =>
      API.user.updateAlias(id, alias),
    {
      // mutationKey: "mutate-user",
      onSuccess: (user) => {
        dispatch({
          type: "UPDATE_INITIAL_ALIAS",
          alias: user.alias,
          userId: user.id,
        });
      },
    }
  );

  useEffect(() => {
    dispatch({ type: "INIT", newUsers: _users as UserData[] });
  }, [_users]);

  const { users, column, direction } = state;

  const onAliasUpdate = useCallback(
    async (userId: string, alias: string) => {
      await updateUserAlias({ alias, id: userId });
    },
    [updateUserAlias]
  );

  console.log(state);

  return (
    <Table sortable fixed>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            sorted={column === "email" ? direction : (null as any)}
            onClick={() => dispatch({ type: "CHANGE_SORT", column: "email" })}
          >
            Email
          </Table.HeaderCell>
          <Table.HeaderCell
            width={3}
            sorted={column === "alias" ? direction : (null as any)}
            onClick={() => dispatch({ type: "CHANGE_SORT", column: "alias" })}
          >
            Alias
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {map(users, ({ alias, email, id, newAlias }) => (
          <Table.Row key={id}>
            <Table.Cell>{email}</Table.Cell>
            <Table.Cell>
              <Form onSubmit={() => onAliasUpdate(id, newAlias)}>
                <Form.Group>
                  <Form.Field
                    name="description"
                    required
                    control={Input}
                    width={5}
                    onChange={(e: Event, { value }: any) =>
                      dispatch({
                        type: "UPDATE_ALIAS",
                        userId: id,
                        alias: value,
                      })
                    }
                    defaultValue={newAlias}
                    type="text"
                  />
                  {alias !== newAlias && (
                    <Button
                      positive
                      disabled={!/^[A-Z]+$/.test(newAlias.trim())}
                      type="submit"
                    >
                      Update
                    </Button>
                  )}
                </Form.Group>
              </Form>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};
