import { Authenticated } from "@partech/auth-react";
import { replace } from "lodash";
import { QueryClientProvider, QueryClient } from "react-query";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { StrategyList } from "../../components/strategy";
import { authContext } from "../../config/auth";
import { ROUTES } from "../../config/routes";
import { AssetList } from "../asset";
import { MenuWrapper } from "../menu";
import { SyncView } from "../sync";
import { UserList } from "../user";

const queryClient = new QueryClient();

const NavigateWrapper = (props: { to?: string; replace?: boolean }) => {
  const location = useLocation();
  console.log("NavigateWrapper", {
    to: props?.to,
    replace: props?.replace,
    location,
  });
  return <Navigate to={props?.to as any} replace={props?.replace as any} />;
};

export const RootApp = () => {
  const assetList = <AssetList />;

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Authenticated context={authContext.context}>
          <Routes>
            <Route
              path={ROUTES.any}
              element={<NavigateWrapper to={ROUTES.root} replace={true} />}
            />
            <Route path={ROUTES.root} element={<MenuWrapper />}>
              <Route
                index
                element={
                  <NavigateWrapper to={ROUTES.strategies} replace={true} />
                }
              />
              <Route path={ROUTES.strategies} element={<StrategyList />} />
              <Route path={ROUTES.sync} element={<SyncView />} />
              <Route path={ROUTES.users} element={<UserList />} />
              <Route path={ROUTES.assets} element={<Outlet />}>
                <Route index element={assetList} />
                <Route path={ROUTES.asset} element={assetList} />
              </Route>
            </Route>
          </Routes>
        </Authenticated>
      </QueryClientProvider>
    </BrowserRouter>
  );
};
