import { filter, find, findIndex, map, size, some, uniqBy } from "lodash";
import { Fragment, useCallback, useState } from "react";
import { Confirm, Button } from "semantic-ui-react";

export const ConfirmButton = ({
  buttonText,
  confirmText,
  onConfirm,
}: {
  confirmTitle?: string;
  confirmText: string;
  onConfirm: () => void | Promise<void>;
  buttonText: string;
}) => {
  const [showConfirm, setShowConfirm] = useState(false);

  const onSubmit = useCallback(async () => {
    try {
      await onConfirm();
    } catch (e) {
      console.error(e);
    }
    setShowConfirm(false);
  }, [onConfirm]);

  return (
    <Fragment>
      <Button onClick={() => setShowConfirm(true)}>{buttonText}</Button>
      <Confirm
        open={showConfirm}
        content={confirmText}
        onCancel={() => setShowConfirm(false)}
        onConfirm={onSubmit}
      />
    </Fragment>
  );
};
