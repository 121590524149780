import { createAuthContext } from "@partech/auth-react";

export const authContext = createAuthContext({
  loginUrl: process.env.REACT_APP_AUTH_URL as string,
  testLogged: () =>
    fetch(`${process.env.REACT_APP_AUTH_URL}/test`, {
      method: "GET",
      credentials: "include",
    }).then((r) => r.status === 200),
});
