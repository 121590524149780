import { Asset, User, Fund, Group, Strategy } from "../types";
import { authContext } from "./auth";

const wrapJson = async <T>(fetchPromise: Promise<Response>) => {
  const res = await fetchPromise;
  if (res.status === 200) {
    return res.json() as Promise<T>;
  }
  throw new Error(`${res.status}: ${await res.text()}`);
};

const BASE_URL = process.env.REACT_APP_API_URL;

export const API = {
  stats: {
    job: {
      overall: () =>
        wrapJson(
          authContext.fetch(`${BASE_URL}/stats/jobs/overall`)
        ) as Promise<{
          all: any;
          "15min": any;
          "30min": any;
          "1h": any;
          "1d": any;
          "7d": any;
          "15d": any;
          "1m": any;
          "3m": any;
        }>,
      series(
        opt: {
          dateRange?: string;
          dateGroup?: "day" | "week" | "month";
          byTopic?: string;
          topics?: string[];
          state?: string;
        } = {}
      ) {
        return wrapJson(
          authContext.fetch(`${BASE_URL}/stats/jobs/series`, {
            method: "POST",
            body: JSON.stringify(opt),
            headers: {
              "content-type": "application/json",
            },
          })
        ) as Promise<{ date: string; value: number; topic?: string }[]>;
      },
    },
  },
  strategy: {
    list() {
      return wrapJson(authContext.fetch(`${BASE_URL}/strategies`)) as Promise<
        Strategy[]
      >;
    },
    syncAllPermissions() {
      return wrapJson(
        authContext.fetch(`${BASE_URL}/strategies/sync`, {
          method: "POST",
          body: JSON.stringify({}),
          headers: { "content-type": "application/json" },
        })
      ) as Promise<void>;
    },
    update(strategy: { name: string; funds: string[] }) {
      return wrapJson(
        authContext.fetch(`${BASE_URL}/strategies/${strategy.name}`, {
          method: "PUT",
          body: JSON.stringify({ funds: strategy.funds }),
          headers: { "content-type": "application/json" },
        })
      ) as Promise<{
        name: string;
        funds: string[];
      }>;
    },
  },
  fund: {
    list() {
      return wrapJson(authContext.fetch(`${BASE_URL}/funds`)) as Promise<
        Fund[]
      >;
    },
  },
  group: {
    list() {
      return wrapJson(authContext.fetch(`${BASE_URL}/groups`)) as Promise<
        Group[]
      >;
    },
  },
  asset: {
    list() {
      return wrapJson(authContext.fetch(`${BASE_URL}/assets`)) as Promise<
        Asset[]
      >;
    },
    get(id: string) {
      return wrapJson(
        authContext.fetch(`${BASE_URL}/assets/${id}`)
      ) as Promise<Asset>;
    },
    update({
      assetId,
      aliases,
      allowedGroups,
      fund,
    }: {
      assetId: string;
      aliases: string[];
      fund: string;
      allowedGroups: string[];
    }) {
      return wrapJson(
        authContext.fetch(`${BASE_URL}/assets/${assetId}`, {
          method: "PUT",
          body: JSON.stringify({
            aliases,
            fund,
            allowed_groups: allowedGroups,
          }),
          headers: { "content-type": "application/json" },
        })
      ) as Promise<Asset>;
    },
    syncPermissions({ assetId }: { assetId: string }) {
      return wrapJson(
        authContext.fetch(`${BASE_URL}/assets/${assetId}/sync`, {
          method: "POST",
          body: JSON.stringify({}),
          headers: { "content-type": "application/json" },
        })
      ) as Promise<void>;
    },
    syncAllPermissions() {
      return wrapJson(
        authContext.fetch(`${BASE_URL}/assets/sync`, {
          method: "POST",
          body: JSON.stringify({}),
          headers: { "content-type": "application/json" },
        })
      ) as Promise<void>;
    },
    syncIlevel() {
      return wrapJson(
        authContext.fetch(`${BASE_URL}/ilevel/sync`, {
          method: "POST",
          body: JSON.stringify({}),
          headers: { "content-type": "application/json" },
        })
      ) as Promise<void>;
    },
  },
  user: {
    list() {
      return wrapJson(authContext.fetch(`${BASE_URL}/users`)) as Promise<
        User[]
      >;
    },
    updateAlias(userId: string, alias: string) {
      return wrapJson(
        authContext.fetch(`${BASE_URL}/users/${userId}`, {
          method: "PUT",
          body: JSON.stringify({ alias }),
          headers: { "content-type": "application/json" },
        })
      ) as Promise<User>;
    },
  },
};
