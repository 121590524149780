export const isAnUrl = (str: string) => {
  return (
    str.toLowerCase().startsWith("http://") ||
    str.toLowerCase().startsWith("https://")
  );
};

export const prefixWithHttpProtocol = (str: string) => {
  const urlStr = `${isAnUrl(str) ? "" : "https://"}${str}`;
  return urlStr;
};
