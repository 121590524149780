import dayjs from "dayjs";
import produce from "immer";
import {
  find,
  forEach,
  map,
  noop,
  reduce,
  reverse,
  size,
  sortBy,
} from "lodash";
import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import {
  Dimmer,
  Icon,
  Image,
  Label,
  Loader,
  Segment,
  Table,
} from "semantic-ui-react";
import { API } from "../../config/api";
import { prefixWithHttpProtocol } from "../../lib";
import { Asset, Fund, Group, Strategy } from "../../types";
import { EditAsset } from "./edit";

type DataDef<
  ActionType extends string,
  ActionData extends Record<string, any>
> = {
  type: ActionType;
  data: ActionData;
};

type Action =
  | DataDef<
      "UPDATE_ASSETS",
      {
        assets: Asset[];
      }
    >
  | DataDef<
      "CHANGE_SORT",
      {
        column: string;
        direction: string;
      }
    >;

type State = { assets: Asset[]; column: string; direction: string };

const reducer = produce((prevState: State, { data, type }: Action) => {
  switch (type) {
    case "UPDATE_ASSETS":
      if (!prevState.column) {
        prevState.assets = data.assets;
      } else {
        prevState.assets = sortBy(
          data.assets,
          [prevState.column],
          [prevState.direction === "ascending" ? "asc" : "desc"]
        ) as any;
      }
      break;
    case "CHANGE_SORT":
      if (prevState.column === data.column) {
        prevState.assets = reverse(prevState.assets);
        prevState.direction =
          prevState.direction === "ascending" ? "descending" : "ascending";
      } else {
        prevState.assets = sortBy(prevState.assets, [data.column]) as any;
        prevState.direction = "ascending";
        prevState.column = data.column as any;
      }
      break;
    default:
      throw new Error();
  }
});

const useAssets = (strategies: Strategy[]) => {
  const strategyByFund = useMemo(() => {
    const init: Record<string, string> = {};
    return reduce(
      strategies,
      (acc, { name, funds }) => {
        forEach(funds, (fund) => {
          acc[fund] = name;
        });
        return acc;
      },
      init
    );
  }, [strategies]);

  const mapAsset = useCallback(
    (asset: Asset) => {
      return {
        ...asset,
        strategy: asset.fund ? strategyByFund[asset.fund] : null,
      };
    },
    [strategyByFund]
  );

  const mapAssets = useCallback(
    (assets: Asset[]) => {
      return map(assets, mapAsset);
    },
    [mapAsset]
  );

  const [{ assets, column, direction }, dispatch] = useReducer(reducer, {
    assets: [],
    column: "",
    direction: "",
  });

  const { isLoading: isLoadingAssets, data } = useQuery(
    "assets",
    () => API.asset.list(),
    {
      enabled: Boolean(size(strategies)),
      onSuccess: (assets) => {
        dispatch({
          type: "UPDATE_ASSETS",
          data: { assets: mapAssets(assets) },
        });
      },
    }
  );

  const [selectedAsset, setSelectedAsset] = useState<Asset | null>();

  const clearAsset = useCallback(() => {
    setSelectedAsset(null);
  }, []);

  const { assetId } = useParams() || {};

  useEffect(() => {
    const asset = find(assets, ({ id }) => id === assetId);
    if (asset) {
      setSelectedAsset(asset);
    } else {
      setSelectedAsset(null);
    }
  }, [assetId, assets]);

  const sortColumn = useCallback(
    (column: string) => {
      dispatch({ type: "CHANGE_SORT", data: { column, direction } });
    },
    [direction]
  );

  return {
    assets,
    dispatch,
    clearAsset,
    sortColumn,
    asset: assetId && selectedAsset?.id === assetId ? selectedAsset : null,
    isLoadingAssets,
    column,
    direction,
  };
};

export const SortedColumn = ({
  activeColumn,
  column,
  text,
  direction,
  sortColumn = noop,
}: {
  sortColumn: (column: string) => void;
  direction: string;
  activeColumn: string;
  column?: string;
  text: string;
}) => {
  if (column) {
    return (
      <Table.HeaderCell
        sorted={column === activeColumn ? direction : (null as any)}
        onClick={() => {
          sortColumn(column);
        }}
      >
        {text}
      </Table.HeaderCell>
    );
  }
  return <Table.HeaderCell>{text}</Table.HeaderCell>;
};

export const AssetList = () => {
  const { data: strategies } = useQuery("strategies", () =>
    API.strategy.list()
  );
  const { data: funds } = useQuery("funds", () => API.fund.list());
  const { data: groups } = useQuery("groups", () => API.group.list());

  const {
    assets,
    clearAsset,
    asset,
    isLoadingAssets,
    sortColumn,
    column,
    direction,
  } = useAssets(strategies || []);

  const navigate = useNavigate();

  const onEditionDone = useCallback(() => {
    if (asset) {
      clearAsset();
      navigate("../");
    }
  }, [clearAsset, navigate, asset]);

  return (
    <>
      {
        <EditAsset
          funds={funds as Fund[]}
          groups={groups as Group[]}
          asset={asset}
          onEditionDone={onEditionDone}
        />
      }

      {isLoadingAssets && (
        <Segment>
          <Dimmer active>
            <Loader />
          </Dimmer>
        </Segment>
      )}

      <Table sortable selectable>
        <Table.Header>
          <Table.Row>
            <SortedColumn
              text="Name"
              activeColumn={column}
              direction={direction}
              column="name"
              sortColumn={sortColumn}
            />
            <SortedColumn
              text="Aliases"
              activeColumn={column}
              direction={direction}
              sortColumn={sortColumn}
            />
            <SortedColumn
              text="Folder Access"
              activeColumn={column}
              direction={direction}
              sortColumn={sortColumn}
            />
            <SortedColumn
              text="Fund"
              activeColumn={column}
              direction={direction}
              column="fund"
              sortColumn={sortColumn}
            />
            <SortedColumn
              text="Strategy"
              activeColumn={column}
              direction={direction}
              sortColumn={sortColumn}
            />
            <SortedColumn
              text="Website"
              activeColumn={column}
              direction={direction}
              sortColumn={sortColumn}
            />
            <SortedColumn
              text="Asset URL"
              activeColumn={column}
              direction={direction}
              sortColumn={sortColumn}
            />
            <SortedColumn
              text="Boardpack Folder"
              activeColumn={column}
              direction={direction}
              sortColumn={sortColumn}
            />
            {/* <SortedColumn
              text="Affinity Sharepoint URL"
              activeColumn={column}
              direction={direction}
              sortColumn={sortColumn}
            /> */}
            <SortedColumn
              text="First sync on"
              activeColumn={column}
              direction={direction}
              sortColumn={sortColumn}
            />
            <SortedColumn
              text="Last permission sync"
              activeColumn={column}
              direction={direction}
              sortColumn={sortColumn}
            />
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {map(
            assets,
            ({
              id,
              ilevel_name,
              ilevel_website,
              sharepoint_url,
              ilevel_url,
              aliases,
              created_at,
              fund,
              strategy,
              allowed_groups,
              sharepoint_last_permission_sync_success,
            }) => (
              <Table.Row
                key={id}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(id);
                }}
              >
                <Table.Cell>{ilevel_name}</Table.Cell>
                <Table.Cell>
                  {map(aliases, (alias) => (
                    <Label key={alias}>{alias}</Label>
                  ))}
                </Table.Cell>
                <Table.Cell>
                  {map(allowed_groups, (group) => (
                    <Label key={group}>{group}</Label>
                  ))}
                </Table.Cell>
                <Table.Cell>{fund}</Table.Cell>
                <Table.Cell>{strategy}</Table.Cell>
                <Table.Cell>
                  {ilevel_website && (
                    <a
                      href={prefixWithHttpProtocol(ilevel_website)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Icon name="world" size="large" />
                    </a>
                  )}
                </Table.Cell>
                <Table.Cell>
                  <Image
                    avatar
                    src={"/ilevel_icon.png"}
                    href={ilevel_url}
                    target="_blank"
                  />
                </Table.Cell>
                <Table.Cell>
                  {sharepoint_url && (
                    <Image
                      // style={{ width: 30 }}
                      size="tiny"
                      src={"/finance_icon.png"}
                      href={sharepoint_url}
                      target="_blank"
                    />
                  )}
                </Table.Cell>
                {/* <Table.Cell>{sharepoint_id}</Table.Cell> */}
                <Table.Cell>
                  {dayjs(created_at).format("YYYY-MM-DD")}
                </Table.Cell>
                <Table.Cell>
                  {sharepoint_last_permission_sync_success &&
                    dayjs(sharepoint_last_permission_sync_success).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )}
                </Table.Cell>
              </Table.Row>
            )
          )}
        </Table.Body>
      </Table>
    </>
  );
};
