import { filter, find, findIndex, map, size, some, uniqBy } from "lodash";
import React, {
  createRef,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import produce from "immer";
import { useQuery } from "react-query";
import {
  Accordion,
  Divider,
  Dropdown,
  Grid,
  Header,
  Icon,
  Label,
  List,
  Button,
  Confirm,
  StepTitle,
} from "semantic-ui-react";
import { API } from "../../config/api";

type ViewableFunds = { name: string; removed: boolean; added: boolean }[];

const stringToOption = (str: string) => ({ key: str, value: str, text: str });
const fundsToOptions = (funds: ViewableFunds) =>
  map(funds, (f) => stringToOption(f.name));

export const StrategyList = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [showConfirm, setShowConfirm] = useState(false);
  const {
    data: strategies,
    isLoading: isLoadingStrategies,
    refetch: refetchStrategies,
  } = useQuery("strategies", () => API.strategy.list());

  const [activeStrategy, setActiveStrategy] = useState<{
    name: string;
    funds: ViewableFunds;
  }>();

  const { data: funds } = useQuery("funds", () => API.fund.list());

  const unclassifiedFundsOptions = useMemo(() => {
    return map(
      filter(
        funds,
        ({ name: fund }) =>
          !Boolean(
            find(strategies, ({ funds: _funds }) => _funds.includes(fund))
          )
      ),
      ({ name }) => ({ key: name, value: name, text: name })
    );
  }, [strategies, funds]);

  const [showedOptions, setShowedOptions] = useState(unclassifiedFundsOptions);

  useEffect(() => {
    const strategy = strategies?.[activeIndex];

    if (strategy) {
      const { name, funds } = strategy;
      setActiveStrategy({
        name,
        funds: map(funds, (fund) => ({
          name: fund,
          removed: false,
          added: false,
        })),
      });
      setShowedOptions(unclassifiedFundsOptions || []);
    }
  }, [strategies, activeIndex, unclassifiedFundsOptions]);

  const onSelectOption = useCallback(
    (fund: string) => {
      if (!activeStrategy) {
        return;
      }
      const newFunds = produce(
        activeStrategy?.funds || [],
        (prevFunds: any[]) => {
          let existingFund = find(prevFunds, (f) => f.name === fund);
          if (existingFund && existingFund.removed) {
            existingFund.removed = false;
            return prevFunds;
          }
          return [...prevFunds, { name: fund, added: true }];
        }
      );

      setActiveStrategy({ name: activeStrategy.name, funds: newFunds });

      const newShowedOptions = filter(
        uniqBy(
          [
            ...unclassifiedFundsOptions,
            ...showedOptions,
            ...fundsToOptions(newFunds),
          ],
          "key"
        ),
        (opt) =>
          !Boolean(newFunds.find((f) => f.name === opt.value && !f.removed))
      );

      setShowedOptions(newShowedOptions);
    },
    [activeStrategy, unclassifiedFundsOptions, showedOptions]
  );

  const onDeleteOption = useCallback(
    (fund: string) => {
      if (!activeStrategy) {
        return;
      }
      const newFunds = produce(
        activeStrategy?.funds || [],
        (prevFunds: any[]) => {
          const existingFundIndex = findIndex(
            prevFunds,
            (f) => f.name === fund
          );
          let existingFund;

          if (existingFundIndex >= 0) {
            existingFund = prevFunds[existingFundIndex];
          }

          if (existingFund && existingFund.added) {
            return [
              ...prevFunds.slice(0, existingFundIndex),
              ...prevFunds.slice(existingFundIndex + 1),
            ];
          } else if (existingFund) {
            existingFund.removed = !existingFund.removed;
          }
        }
      );

      setActiveStrategy({ name: activeStrategy.name, funds: newFunds });
      const newShowedOptions = filter(
        uniqBy(
          [
            ...unclassifiedFundsOptions,
            ...showedOptions,
            ...fundsToOptions(newFunds),
          ],
          "key"
        ),
        (opt) =>
          !Boolean(newFunds.find((f) => f.name === opt.value && !f.removed))
      );

      setShowedOptions(newShowedOptions);
    },
    [activeStrategy, unclassifiedFundsOptions, showedOptions]
  );

  const anyUpdate = useMemo(() => {
    return some(activeStrategy?.funds, (f) => f.removed || f.added);
  }, [activeStrategy]);

  const onSubmit = useCallback(async () => {
    if (!activeStrategy) {
      setShowConfirm(false);
      return;
    }

    try {
      if (anyUpdate) {
        const funds = [];
        for (const { removed, name } of activeStrategy.funds) {
          if (!removed) {
            funds.push(name);
          }
        }
        await API.strategy.update({ name: activeStrategy?.name, funds });
        await refetchStrategies();
      }
    } finally {
      setShowConfirm(false);
    }
  }, [anyUpdate, activeStrategy, refetchStrategies]);

  if (!size(strategies)) {
    return <div>Empty</div>;
  }

  return (
    <Fragment>
      <Confirm
        open={showConfirm}
        content={"Permissions might change! Be extremelly careful!"}
        onCancel={() => {
          setShowConfirm(false);
        }}
        onConfirm={onSubmit}
      />
      <Header as="h4">Unclassified:</Header>
      <List divided horizontal size={"small"}>
        {map(unclassifiedFundsOptions, ({ key, text }) => {
          return (
            <List.Item key={key}>
              <List.Content>
                <List.Header>{text}</List.Header>
              </List.Content>
            </List.Item>
          );
        })}
      </List>
      <Divider hidden={true} />
      <Accordion fluid styled>
        {map(strategies, ({ name, allowed_groups }, index) => {
          return (
            <React.Fragment key={name}>
              <Accordion.Title
                active={activeIndex === index}
                index={index}
                onClick={() => setActiveIndex(index)}
              >
                <Icon name="dropdown" />
                {name}
              </Accordion.Title>
              <Accordion.Content active={activeIndex === index}>
                <Grid.Row>
                  {Boolean(size(showedOptions)) && (
                    <>
                      <Grid.Column>
                        <Dropdown
                          placeholder="Fund to add"
                          fluid
                          search
                          selection
                          minCharacters={1}
                          onChange={(e, { value }) => {
                            onSelectOption(value as string);
                          }}
                          options={showedOptions}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Divider hidden={true} />
                      </Grid.Column>
                    </>
                  )}
                  <Grid.Column>
                    <StepTitle>Funds</StepTitle>
                  </Grid.Column>
                  <Grid.Column>
                    <List horizontal size={"small"}>
                      {map(
                        activeStrategy?.funds,
                        ({ name, added, removed }) => {
                          return (
                            <List.Item key={name}>
                              <List.Content>
                                <Label
                                  color={
                                    added
                                      ? "green"
                                      : removed
                                      ? "red"
                                      : undefined
                                  }
                                  image
                                >
                                  {removed && (
                                    <Icon
                                      onClick={() => onDeleteOption(name)}
                                      name="add"
                                      style={{ cursor: "pointer" }}
                                    />
                                  )}
                                  {name}
                                  {!removed && (
                                    <Icon
                                      onClick={() => onDeleteOption(name)}
                                      name="delete"
                                    />
                                  )}
                                </Label>
                              </List.Content>
                            </List.Item>
                          );
                        }
                      )}
                    </List>
                  </Grid.Column>
                  <Grid.Column>
                    <StepTitle>Groups</StepTitle>
                  </Grid.Column>
                  <Grid.Column>
                    <List horizontal size={"small"}>
                      {map(allowed_groups, (name) => {
                        return (
                          <List.Item key={name}>
                            <List.Content>
                              <Label image>{name}</Label>
                            </List.Content>
                          </List.Item>
                        );
                      })}
                    </List>
                  </Grid.Column>
                  <Grid.Column>
                    <Button
                      color="green"
                      disabled={!anyUpdate}
                      loading={isLoadingStrategies}
                      onClick={() => setShowConfirm(true)}
                    >
                      Submit
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Accordion.Content>
            </React.Fragment>
          );
        })}
      </Accordion>
    </Fragment>
  );
};
